import {
  ConnectionStates,
  Content,
  Credentials,
  Language,
  Load,
  Metadata,
  PreferredLanguage,
  SelectedLanguage,
  SenderEvents,
} from "@telia-company/tv.unified-sender-js";
import { TLanguageCode } from "@telia-company/tv.web-playback-sdk";
import { useCallback } from "react";

import { useChromecastContext } from "~/providers/ChromecastProvider";

export type PlaybackSpec = {
  accessControl: string;
  videoId: string;
  videoIdType: string;
  watchMode: string;
};

export type Series = {
  episode: number;
  season: number;
  title: string;
};

export type SelectedLanguages = {
  assetLanguage?: "undefined" | keyof TLanguageCode;
  audio?: keyof TLanguageCode;
  text?: keyof TLanguageCode;
};

export type CastPlaybackSpecFnParams = {
  autoplay?: boolean;
  /**
   * If playbackSpec.watchMode is LIVE currentTime will always be sent as undefined to the receiver
   */
  currentTime?: number;
  playbackSpec: PlaybackSpec;
  selected?: SelectedLanguages;
  series?: Series;
  title?: string;
};

export const useCastPlaybackSpec = () => {
  const {
    credentials,
    preferredLanguage,
    sender,
    senderState,
    serviceCountry,
  } = useChromecastContext();

  const castPlaybackSpec = useCallback(
    async ({
      autoplay = true,
      currentTime = 0,
      playbackSpec,
      selected,
      series,
      title = "",
    }: CastPlaybackSpecFnParams) => {
      if (!sender) return;
      const cast = () => {
        const content = new Content({
          accessControl: playbackSpec.accessControl,
          auxiliaryData: {
            assetLanguage: selected?.assetLanguage,
          },
          contentId: playbackSpec.videoId,
          contentType: playbackSpec.videoIdType,
          metadata: new Metadata({ images: [], series, title }),
          regionalChannels: {},
          serviceCountry: serviceCountry.toLowerCase(),
          type: "Content",
          watchMode: playbackSpec.watchMode,
        });

        const language = new Language({
          preferred: new PreferredLanguage(preferredLanguage),
          selected: new SelectedLanguage(selected),
        });

        const load = new Load({
          autoplay,
          content,
          credentials: new Credentials(credentials),
          // Trust that the client knows where to start
          currentTime,
          language,
        });

        return sender.cast(load);
      };

      if (senderState === ConnectionStates.Connecting) {
        const onConnectedHandler = () => {
          cast();
          sender.off(SenderEvents.ConnectionStateChanged, onConnectedHandler);
        };
        sender.on(SenderEvents.ConnectionStateChanged, onConnectedHandler);
      } else {
        if (!sender.connected) {
          await sender.connect();
        }
        await cast();
      }
    },
    [credentials, preferredLanguage, serviceCountry, sender, senderState]
  );

  return castPlaybackSpec;
};
