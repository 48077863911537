import {
  AdvertisementBreakStartedEvent,
  AdvertisementBreakTypes,
  AdvertisementInfo,
  AdvertisementStartedEvent,
  AdvertisementTimeUpdateEvent,
  PlaybackErrorEvent,
  StoppedEvent,
} from "../../../base";

import youbora from "youboralib";
import { slugify } from "../../..";
import { TYouboraTrackerConfiguration } from "./Types";
import { convertTrackerErrorToYouboraError, ignore } from "./YouboraTracker";

enum YouboraAdReports {
  START = 0,
  FIRST_QUARTILE = 0.25,
  SECOND_QUARTILE = 0.5,
  THIRD_QUARTILE = 0.75,
  END = 0.98,
}

export class AdsAdapter {
  private breakType: string;
  private adBreakAds: number | null;
  private adInfo?: AdvertisementInfo;
  private adProgress: number;
  private ongoingAdReported: number[];

  private isPaused: boolean;

  public adapter: youbora.Adapter;

  constructor(configuration: TYouboraTrackerConfiguration) {
    const self = this;

    this.breakType = youbora.Constants.AdPosition.Preroll;
    this.adBreakAds = null;
    this.adProgress = 0;
    this.ongoingAdReported = [];
    this.isPaused = false;

    const playerName = configuration.player.playerName;
    const playerVersion = configuration.player.playerVersion;
    const Adapter = youbora.Adapter.extend({
      getVersion(): string | null {
        return `${youbora.VERSION}-${playerName}-${playerVersion}`;
      },

      getPlayerName(): string | null {
        return playerName ?? null;
      },

      getPlayerVersion(): string | null {
        return playerVersion ?? null;
      },

      getPosition(): string {
        return self.breakType;
      },

      getGivenAds(): number {
        return self.adBreakAds || 0;
      },

      getDuration(): number | undefined {
        return self.adInfo?.durationInSeconds;
      },

      getPlayhead(): number {
        return self.adProgress;
      },

      getIsSkippable(): boolean {
        return false;
      },

      getTitle(): string | undefined {
        return self.adInfo?.name;
      },

      getTitle2(): string | undefined {
        return self.adInfo?.campaignId;
      },

      getCreativeId(): string | undefined {
        // Direct sales will have the valid id as Custom Id.
        let creativeId = self.adInfo?.customId;

        // For trailers and bumpers we usually do not have a custom id, but rather need to handle some edge cases.
        if (
          (!self.adInfo?.customId || self.adInfo.customId === "N/A") &&
          self.adInfo?.id
        ) {
          creativeId = self.adInfo.id;
        } else if (
          !self.adInfo?.customId &&
          !self.adInfo?.id &&
          self.adInfo?.name
        ) {
          creativeId = slugify(self.adInfo?.name);
        }
        // Programmetic ads does have an id, though we want these to be separated by naming convention in Youbora ads.
        if (creativeId?.toLowerCase().includes("programmatic")) {
          creativeId = `programmatic_${self.adInfo?.id}`;
        }

        return creativeId;
      },
    });

    this.adapter = new Adapter();
  }

  public advertisementBreakStarted({
    payload,
  }: AdvertisementBreakStartedEvent): void {
    this.breakType =
      payload.breakType === AdvertisementBreakTypes.PREROLL
        ? youbora.Constants.AdPosition.Preroll
        : youbora.Constants.AdPosition.Midroll;

    this.adBreakAds = payload.advertisements.length;

    this.adapter.fireBreakStart();
    this.adapter.fireStart();
  }

  public advertisementBreakEnded(): void {
    this.adapter.fireStop();
    this.adapter.fireBreakStop();
  }

  public advertisementStarted({ payload }: AdvertisementStartedEvent): void {
    this.adInfo = payload;
    this.adapter.fireStart();
    this.adapter.fireJoin();
  }

  public advertisementEnded(): void {
    this.adapter.fireStop();
    this.adInfo = undefined;
    this.ongoingAdReported = [];
  }

  public advertisementTimeUpdate({
    payload,
  }: AdvertisementTimeUpdateEvent): void {
    this.adProgress = payload.advertisementCurrentTime;

    const percentage =
      payload.advertisementCurrentTime / payload.advertisementDuration;

    if (
      percentage > YouboraAdReports.FIRST_QUARTILE &&
      !this.ongoingAdReported.includes(YouboraAdReports.FIRST_QUARTILE)
    ) {
      this.ongoingAdReported.push(YouboraAdReports.FIRST_QUARTILE);
      this.adapter.fireQuartile(1);
    }
    if (
      percentage > YouboraAdReports.SECOND_QUARTILE &&
      !this.ongoingAdReported.includes(YouboraAdReports.SECOND_QUARTILE)
    ) {
      this.ongoingAdReported.push(YouboraAdReports.SECOND_QUARTILE);
      this.adapter.fireQuartile(2);
    }
    if (
      percentage > YouboraAdReports.THIRD_QUARTILE &&
      !this.ongoingAdReported.includes(YouboraAdReports.THIRD_QUARTILE)
    ) {
      this.ongoingAdReported.push(YouboraAdReports.THIRD_QUARTILE);
      this.adapter.fireQuartile(3);
    }
  }

  public paused(): void {
    this.isPaused = true;
    this.adapter.firePause();
  }

  public playing(): void {
    if (this.isPaused) {
      this.isPaused = false;
      this.adapter.fireResume();
    } else {
      this.adapter.fireStart();
      this.ongoingAdReported = [YouboraAdReports.START];
    }
  }

  public stopped({ payload }: StoppedEvent): void {
    this.adapter.fireStop({
      adPlayhead: Math.round(this.adProgress || payload.currentTime || 0),
    });
  }

  public buffering(): void {
    this.adapter.fireBufferBegin();
  }

  public buffered(): void {
    this.adapter.fireBufferEnd();
  }

  public error({ payload }: PlaybackErrorEvent) {
    if (!payload.error || ignore(payload.error)) return;

    // if errors occur before `fireStart` was called we *have* to trigger it here,
    // otherwise `fireStop` won't work
    !this.adapter.flags.isStarted && this.adapter.fireStart();

    this.adapter.fireError(convertTrackerErrorToYouboraError(payload.error));

    this.adapter.fireStop();
  }

  public reset(): void {
    this.breakType = youbora.Constants.AdPosition.Preroll;
    this.adBreakAds = null;
    this.adInfo = undefined;
    this.adProgress = 0;
    this.isPaused = false;
  }

  public destroy(): void {
    this.reset();
  }
}
