/* eslint-disable no-console */

import {
  AuthenticationInfo,
  AuthenticationService,
  Credentials,
  FetchRequestFactory,
  MetadataService,
} from "@telia-company/tv.web-playback-sdk";
import { ChromecastButton } from "@telia-company/tv.web-player-cc-sender";
import {
  getFromLocalStorage,
  getUuid,
  LocalStorageKey,
  setInLocalStorage,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";
import React, { useCallback, useEffect, useState } from "react";

import { ChromecastThing } from "./components/ChromecastThing";
import { EnvironmentBar } from "./components/environment-bar";
import { LoginForm } from "./components/login-form";
import { MainContainer } from "./components/main-container";
import { ServiceCountryBar } from "./components/service-country-bar";
import { Spinner } from "./components/spinner";
import { getPlayerConfig, getServiceConfig } from "./config/ref-app-config";
import { useEnvironment } from "./hooks/change-env.hook";
import { ServiceCountries } from "./misc/constants";
import {
  changePlayerConfAction,
  loginStartAction,
  loginSuccessAction,
  logoutAction,
  RefAppDispatchContext,
  RefAppStateContext,
  useRefStateReducer,
} from "./reducer/ref.reducer";
import "./styles/App.css";

const localDeviceId = getFromLocalStorage(LocalStorageKey.REF_APP_DEVICE_ID);

const deviceId = localDeviceId || getUuid();

if (!localDeviceId) {
  setInLocalStorage(LocalStorageKey.REF_APP_DEVICE_ID, deviceId);
}

const applicationSessionId = getUuid();

const metadataService = new MetadataService();
const auth = new AuthenticationService();
const requestFactory = new FetchRequestFactory({
  authenticationComponent: auth,
  useProfileHeader: true,
});

metadataService.initialize(
  getServiceConfig({
    applicationSessionId,
    deviceId,
    env: getFromLocalStorage(LocalStorageKey.REF_APP_ENV)?.id || "TELIA_STAGE",
    serviceCountry:
      getFromLocalStorage(LocalStorageKey.REF_APP_SERVICECOUNTRY) || "SE",
  })
);

auth.initialize(
  getServiceConfig({
    applicationSessionId,
    deviceId,
    env: getFromLocalStorage(LocalStorageKey.REF_APP_ENV)?.id || "TELIA_STAGE",
    serviceCountry:
      getFromLocalStorage(LocalStorageKey.REF_APP_SERVICECOUNTRY) || "SE",
  })
);

metadataService.attachRequestFactory(requestFactory);

const App: React.FC = () => {
  const [state, dispatch] = useRefStateReducer({
    metadataService,
    requestFactory,
  });

  const [channels, setChannels] = useState<TUnfortunatelyAny[]>([]);
  const { env, isLoggedIn, serviceCountry, signingIn, userId } = state;

  const doLogin = useCallback(
    ({ password, username }: Credentials): Promise<void> => {
      if (!username || !password) return Promise.resolve();

      dispatch(loginStartAction());

      return auth
        .login({ password, username })
        .then((authInfo: AuthenticationInfo) => {
          auth.getUserInfo().then((userInfo) => {
            dispatch(
              loginSuccessAction({
                chromecastAuthStuff: {
                  profileHeader: auth.getProfileHeader(),
                  refreshToken: authInfo.refreshToken,
                },
                isLoggedIn: authInfo.isAuthenticated,
                userId: userInfo ? userInfo.userId : null,
              })
            );
          });
        })
        .catch((e) => {
          dispatch(logoutAction());
          console.error(e);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    metadataService.getChannels().then((response) => {
      setChannels(response);
    });
  }, [setChannels]);

  useEffect(() => {
    if (!userId) return;

    dispatch(
      changePlayerConfAction(
        getPlayerConfig({
          applicationSessionId,
          deviceId,
          env: env.id,
          iAuth: auth,
          serviceCountry,
          userId,
        })
      )
    );
  }, [env, userId, dispatch, serviceCountry]);

  useEnvironment({
    applicationSessionId,
    auth,
    deviceId,
    dispatch,
    doLogin,
    env,
    metadataService,
    serviceCountry,
  });

  return (
    <RefAppStateContext.Provider value={state}>
      <RefAppDispatchContext.Provider value={dispatch}>
        <EnvironmentBar
          auth={auth}
          deviceId={deviceId}
          sessionId={applicationSessionId}
        />
        <ServiceCountryBar serviceCountries={ServiceCountries} />

        {signingIn && <Spinner />}

        {!signingIn &&
          (isLoggedIn ? (
            <ChromecastThing
              auth={state.auth}
              env={env.id}
              serviceCountry={serviceCountry}
            >
              <ChromecastButton />
              <MainContainer channelList={channels} />
            </ChromecastThing>
          ) : (
            <LoginForm doLogin={doLogin} />
          ))}
      </RefAppDispatchContext.Provider>
    </RefAppStateContext.Provider>
  );
};

export default App;
