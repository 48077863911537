import {
  ChannelMetadata,
  MetadataService,
  Program,
} from "@telia-company/tv.web-playback-sdk";
import {
  TContentMetadata,
  TNpvrInfo,
  TPlaybackSpec,
} from "@telia-company/tv.web-player-shared";

export type TTimeRange = {
  end: Date | number;
  start: Date | number;
};
export type TGetChannelMetadata = {
  metadataService: MetadataService;
  playbackSpec: TPlaybackSpec;
  timeRange: TTimeRange;
  timestamp: null | number;
};
export type TCachedChannelData = {
  channelData: ChannelMetadata | null;
  lastFetch: null | number;
};

const ongoing = (program: Program, timestamp: null | number) => {
  if (!timestamp) return false;
  const hasStarted = timestamp > program.startTime;
  const hasEnded = timestamp > program.endTime;

  return hasStarted && !hasEnded;
};

/* const cached: {
  metadata: Record<string, TCachedChannelData>;
} = {
  metadata: {},
}; */

/* const getFreshOrCachedPrograms = (
  metadataService: MetadataService,
  playbackSpec: TPlaybackSpec,
  timeRange: TTimeRange
) => {
  const cachedChannel = cached.metadata?.[playbackSpec.videoId];

  // since we fetch -4 to +8 hours of EPG, we can cache it for 4 hours
  if (
    cachedChannel &&
    cachedChannel.channelData &&
    cachedChannel.lastFetch &&
    Date.now() - cachedChannel.lastFetch < 4 * 60 * 60 * 1000
  ) {
    return Promise.resolve(cachedChannel.channelData);
  }
  return metadataService
    .getChannelMetadata({ playbackSpec, timeRange, limit: 2 })
    .then((channelData) => {
      cached.metadata[playbackSpec.videoId] = {
        channelData,
        lastFetch: Date.now() + Math.floor(Math.random() * 15) * 1000, // add a random 15s offset, to avoid traffic spikes
      };

      return channelData;
    });
};
*/

const padPrograms = (metadata: ChannelMetadata) => {
  if (!metadata.programs.length) return [];
  const gaps: Program[] = [];
  const { programs } = metadata;
  programs.reduce((prev, current) => {
    if (prev && current.startTime > prev.endTime) {
      // fill all gaps with placeholder metadata
      gaps.push({
        endTime: current.startTime,
        id: "filler",
        live: true,
        media: {
          description: "",
          id: "filler",
          images: {},
          title: metadata.name,
        },
        rerun: false,
        startTime: prev.endTime,
      });
    }
    return current;
  });
  return programs.concat(gaps).sort((a, b) => a.startTime - b.startTime);
};

export const getChannelMetadata = ({
  metadataService,
  playbackSpec,
  timeRange,
  timestamp,
}: TGetChannelMetadata): Promise<TContentMetadata> =>
  // not using cached programs because of nPVR, we need to refetch metadata on program switch
  // getFreshOrCachedPrograms(metadataService, playbackSpec, timeRange).then(
  metadataService
    .getChannelMetadata({ limit: 2, playbackSpec, timeRange })
    .then((metadata) => {
      // loop through metadata.programs and find any gaps (where endTime of program1 is less than startTime of program2)
      const paddedPrograms = padPrograms(metadata);

      const ongoingProgram = paddedPrograms.find((program) =>
        ongoing(program, timestamp)
      );

      if (ongoingProgram) {
        return {
          canStartOver: !!ongoingProgram.startoverPlaybackSpec,
          endTime: ongoingProgram.endTime,
          episode: ongoingProgram.media.episode,
          logo: metadata.icons.dark,
          npvrInfo: ongoingProgram.npvrInfo as TNpvrInfo,
          season: ongoingProgram.media.season,
          startoverPlaybackSpec:
            ongoingProgram.startoverPlaybackSpec as TPlaybackSpec,
          startTime: ongoingProgram.startTime,
          title:
            ongoingProgram.media.seriesTitle ||
            ongoingProgram.media.title ||
            metadata.name,
        };
      }
      return {
        endTime:
          metadata.programs?.[0]?.startTime || Date.now() + 10 * 60 * 1000,
        logo: metadata.icons.dark,
        title: metadata.name,
      };
    });
