import { Track } from "@telia-company/tv.unified-sender-js";
import React from "react";

import { SvgLanguageSettings } from "~/components/Icons";
import { Popup } from "~/components/Popup";
import { useChromecastContext } from "~/providers/ChromecastProvider";

import { TrackList } from "./TrackList";

export type LanguagePickerProps = {
  activeAudioTrack?: Track;
  activeTextTrack?: Track;
  audioTracks?: Track[];
  className?: string;
  disabled?: boolean;
  onChangeAudioTrack: (track: Track) => void;
  onChangeTextTrack: (track: Track) => void;
  textTracks?: Track[];
};

export const LanguagePicker: React.FC<LanguagePickerProps> = ({
  activeAudioTrack,
  activeTextTrack,
  audioTracks,
  className,
  disabled,
  onChangeAudioTrack,
  onChangeTextTrack,
  textTracks,
}) => {
  const { translations } = useChromecastContext();

  return (
    <Popup className={className} disabled={disabled} Icon={SvgLanguageSettings}>
      {Boolean(textTracks?.length) && (
        <TrackList
          activeTrack={activeTextTrack}
          onClickTrack={onChangeTextTrack}
          title={translations?.subtitleTracks || "Subtitles"}
          tracks={textTracks}
        />
      )}
      {Boolean(audioTracks?.length) && (
        <TrackList
          activeTrack={activeAudioTrack}
          onClickTrack={onChangeAudioTrack}
          title={translations?.audioTracks || "Audio"}
          tracks={audioTracks}
        />
      )}
    </Popup>
  );
};
