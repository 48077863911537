import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useRef, useState } from "react";

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export type StyledTrackContainerProps = Pick<
  ProgressBarProps,
  "canSeek" | "disabled"
>;

const StyledTrackContainer = styled.div<StyledTrackContainerProps>`
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  cursor: ${({ canSeek, disabled }) => {
    if (disabled) {
      return "not-allowed";
    }
    if (canSeek) {
      return "pointer";
    }
    return null;
  }};
`;

const Dot = styled.div<{ horizontal?: boolean }>`
  ${({ horizontal }) =>
    horizontal ? "margin-left: -0.45em;" : "margin-top: -0.45em;"}

  width: 1em;
  height: 1em;
  border-radius: 1em;

  background: #fff;
`;

const ProgressDot = styled(Dot)`
  position: absolute;
  pointer-events: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const StyledTrack = styled.div<{ progress: number }>`
  background: ${({ progress, theme }) =>
    `linear-gradient(to right, ${theme.designTokens.color.content.progressElapsed} ${progress}%, ${theme.designTokens.color.content.progressDuration} ${progress}%)`};

  height: 4px;
  transition: height 0.2s ease;
  width: 100%;
`;

export type StyledSeekTooltipProps = {
  seekPosition: number;
};

const StyledSeekTooltip = styled.div<StyledSeekTooltipProps>`
  position: absolute;
  top: -40px;

  min-width: 50px;
  padding: 8px;
  text-align: center;
  pointer-events: none;

  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(40px);

  ${({ seekPosition, theme }) => css`
    left: ${seekPosition}px;
    color: ${theme.designTokens.color.text.primary};
    font-family: ${theme.designTokens.typography.textPrimary.fontFamily};
    font-size: ${theme.designTokens.typography.textPrimary.fontSize};
    font-weight: ${theme.designTokens.typography.textPrimary.fontWeight};
    border-radius: ${theme.designTokens.radius.labelFloating};
  `}
`;

export type StyledLiveIndicatorProps = {
  position: number;
};

const StyledEndRangeIndicator = styled.div<StyledLiveIndicatorProps>`
  position: absolute;
  height: 4px;
  width: 4px;
  pointer-events: none;
  ${({ position, theme }) => css`
    left: ${position}%;
    background-color: ${theme.designTokens.color.text.primary};
  `}
`;

// Copied from ui/src/utils.ts
const getZeroBasedTimeString = (totalSeconds: number): string => {
  const negative = totalSeconds < 0;
  const absoluteSeconds = Math.abs(totalSeconds);
  // Returns a string with an amount of hours, minutes and seconds
  // Based on http://jsfiddle.net/StevenIseki/apg8yx1s/
  const hours = Math.floor(absoluteSeconds / 3600);
  const minutes = Math.floor((absoluteSeconds - hours * 3600) / 60);
  const seconds = Math.floor(absoluteSeconds - hours * 3600 - minutes * 60);

  const timeArray = [minutes, seconds];
  if (hours > 0) {
    timeArray.unshift(hours);
  }

  return (
    (negative ? "-" : "") +
    timeArray
      .map((num) => {
        if (!Number.isInteger(num)) {
          return "00";
        }
        if (num < 10) {
          return `0${num}`;
        }
        return num;
      })
      .join(":")
  );
};

const TOOLTIP_HORIZONTAL_PADDING = 16;

export type ProgressBarProps = {
  canSeek: boolean;
  className?: string;
  currentTime: number;
  disabled?: boolean;
  duration: number;
  onSeeking: (time: number) => void;
  seekableEndRange?: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  canSeek,
  className,
  currentTime,
  disabled,
  duration,
  onSeeking,
  seekableEndRange,
}) => {
  const seekTooltipRef = useRef<HTMLDivElement>(null);
  const [seekPosition, setSeekPosition] = useState<number>();
  const [seekTime, setSeekTime] = useState<number>();
  const [seekString, setSeekString] = useState<string>();
  const [isSeekingPastSeekableRange, setIsSeekingPastSeekableRange] =
    useState<boolean>(false);

  const progress = !currentTime ? 0 : (currentTime / duration) * 100;
  const seekableEndRangeProgress = seekableEndRange
    ? (seekableEndRange / duration) * 100
    : undefined;

  const onMouseMoveHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled || !canSeek) {
      return;
    }

    const percentage =
      event.nativeEvent.offsetX / (event.target as HTMLDivElement).clientWidth;

    if (percentage) {
      const nextSeekTime = duration * percentage;

      if (seekableEndRange && nextSeekTime > seekableEndRange) {
        setIsSeekingPastSeekableRange(true);
        return;
      }

      const nextSeekString = getZeroBasedTimeString(nextSeekTime);
      const tooltipWidth = seekTooltipRef.current?.offsetWidth;
      let nextSeekPosition = window.outerWidth;

      if (tooltipWidth) {
        if (
          event.nativeEvent.offsetX +
            tooltipWidth / 2 +
            TOOLTIP_HORIZONTAL_PADDING >
          window.outerWidth
        ) {
          nextSeekPosition =
            window.outerWidth - (tooltipWidth + TOOLTIP_HORIZONTAL_PADDING);
        } else if (
          event.nativeEvent.offsetX -
            tooltipWidth / 2 -
            TOOLTIP_HORIZONTAL_PADDING <
          0
        ) {
          nextSeekPosition = TOOLTIP_HORIZONTAL_PADDING;
        } else {
          nextSeekPosition = event.nativeEvent.offsetX - tooltipWidth / 2;
        }
      }

      setSeekTime(nextSeekTime);
      setSeekPosition(nextSeekPosition);
      setSeekString(nextSeekString);
      setIsSeekingPastSeekableRange(false);
    }
  };

  const onMouseLeaveHandler = () => {
    setSeekTime(undefined);
    setSeekPosition(undefined);
    setSeekString(undefined);
    setIsSeekingPastSeekableRange(false);
  };

  const onSeekingHandler = () => {
    if (disabled || !canSeek || isSeekingPastSeekableRange || !seekTime) {
      return;
    }
    onSeeking(seekTime);
  };

  return (
    <StyledContainer className={className}>
      <StyledTrackContainer
        canSeek={canSeek && !isSeekingPastSeekableRange}
        disabled={disabled}
        onClick={onSeekingHandler}
        onMouseLeave={onMouseLeaveHandler}
        onMouseMove={onMouseMoveHandler}
      >
        <StyledTrack progress={progress} />
        {typeof seekPosition !== "undefined" && !isSeekingPastSeekableRange ? (
          <StyledSeekTooltip ref={seekTooltipRef} seekPosition={seekPosition}>
            {seekString}
          </StyledSeekTooltip>
        ) : null}
        {canSeek ? (
          <ProgressDot
            // ref={progressDotRef}
            horizontal
            style={{
              left: `${progress.toFixed(1)}%`,
            }}
          />
        ) : null}
        {typeof seekableEndRangeProgress !== "undefined" &&
        seekableEndRangeProgress ? (
          <StyledEndRangeIndicator position={seekableEndRangeProgress} />
        ) : null}
      </StyledTrackContainer>
    </StyledContainer>
  );
};
