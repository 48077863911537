import {
  ConnectionStates,
  Content,
  Credentials,
  PlaybackStates,
  PreferredLanguage,
  Sender,
  SenderEvents,
  Session,
  SessionEvents,
  StoppedReasons,
  Track,
} from "@telia-company/tv.unified-sender-js";
import { SenderArgs } from "@telia-company/tv.unified-sender-js/types/Sender";
import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ChromecastBar } from "~/components/ChromecastBar";

import { Breakpoints, DesignTokens, ThemeProvider } from "./ThemeProvider";

export type Translations = {
  audioTracks: string;
  episode: string;
  off: string;
  season: string;
  subtitleTracks: string;
};

export type TConnectionStates =
  | "ConnectionStates.Connected"
  | "ConnectionStates.Connecting"
  | "ConnectionStates.Disconnected"
  | "ConnectionStates.Disconnecting";

export const ChromecastContext = createContext<{
  credentials?: Credentials;
  devicesFound?: boolean;
  isChromecastBarVisible: boolean;
  preferredLanguage: PreferredLanguage;
  sender?: Sender;
  senderState?: TConnectionStates;
  serviceCountry: string;
  translations?: Translations;
}>({
  isChromecastBarVisible: false,
  preferredLanguage: { audio: [], text: [] },
  serviceCountry: "",
});

export function useChromecastContext() {
  return useContext(ChromecastContext);
}

export type OnStateChangedData = {
  contentId: string;
};

export type ChromecastProviderProps = {
  applicationId?: string;
  breakpoints: Breakpoints;
  children: React.ReactNode;
  credentials: Credentials;
  designTokens: DesignTokens;
  onStateChanged: (data: OnStateChangedData) => void;
  preferredLanguage: PreferredLanguage;
  readyTimeout?: number;
  serviceCountry: string;
  translations?: Translations;
  unifiedMessageNamespace?: string;
} & SenderArgs;

export const ChromecastProvider: React.FC<ChromecastProviderProps> = ({
  applicationId: newApplicationId,
  breakpoints,
  castFramework = "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1",
  children,
  credentials,
  designTokens,
  initializedTimeout,
  loadCastFramework = true,
  onStateChanged,
  preferredLanguage,
  readyTimeout = 600,
  serviceCountry,
  translations,
  unifiedMessageNamespace = "urn:x-cast:unified.messaging",
}) => {
  const [currentAppId, setCurrentAppId] = useState<string | undefined>();
  const [senderState, setSenderState] = useState<TConnectionStates>();
  const [devicesFound, setDevicesFound] = useState<boolean>(false);
  const [sender, setSender] = useState<Sender>();
  const [session, setSession] = useState<null | Session>();

  const contentIdRef = useRef<string>();

  const [title, setTitle] = useState<string>();
  const [secondaryTitle, setSecondaryTitle] = useState<string>();

  const [activeVolume, setActiveVolume] = useState(100);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [canSeek, setCanSeek] = useState(false);
  const [canPause, setCanPause] = useState(false);

  const [seekableEndRange, setSeekableEndRange] = useState<number>();

  const [isBuffering, setIsBuffering] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const [audioTracks, setAudioTracks] = useState<Track[]>([]);
  const [textTracks, setTextTracks] = useState<Track[]>([]);
  const [activeAudioTrack, setActiveAudioTrack] = useState<Track>();
  const [activeTextTrack, setActiveTextTrack] = useState<Track>();

  const OFF_TEXT_TRACK: Track = useMemo(
    () => ({
      id: "off",
      language: "off",
      name: translations?.off || "Off",
      type: "text",
    }),
    [translations?.off]
  );

  const resetState = useCallback(() => {
    setTitle(undefined);
    setSecondaryTitle(undefined);
    setActiveVolume(100);
    setCurrentTime(0);
    setSeekableEndRange(undefined);
    setDuration(0);
    setCanSeek(false);
    setCanPause(false);
    setIsBuffering(false);
    setIsSeeking(false);
    setIsLoading(false);
    setIsMuted(true);
    setIsPaused(false);
    setIsActive(false);
    setAudioTracks([]);
    setTextTracks([]);
    setActiveAudioTrack(undefined);
    setActiveTextTrack(undefined);

    contentIdRef.current = undefined;
  }, []);

  const dispatchStateChanged = useCallback(() => {
    if (!contentIdRef.current) return;

    onStateChanged({ contentId: contentIdRef.current });
  }, [onStateChanged]);

  const updateTimeStates = useCallback(() => {
    if (
      !session ||
      !session.sessionState?.content?.metadata ||
      !session.sessionState?.playbackSessionState
    )
      return;
    const {
      sessionState: {
        content: {
          accessControl,
          metadata: { sectionInfo },
          watchMode,
        },
        playbackSessionState: {
          currentTime: incomingCurrentTime,
          duration: incomingDuration,
          seekableRange,
        },
      },
    } = session;
    let nextCurrentTime = incomingCurrentTime;
    let nextSeekableEndRange;
    let nextDuration = incomingDuration;
    // if -1 we should handle epg stuff
    if (incomingDuration === -1) {
      if (sectionInfo && sectionInfo.startAbsoluteTime) {
        if (watchMode === "LIVE") {
          nextCurrentTime = incomingCurrentTime;
          nextDuration = sectionInfo.duration;
        } else if (
          watchMode === "STARTOVER" &&
          seekableRange?.end &&
          seekableRange?.end > incomingCurrentTime
        ) {
          nextSeekableEndRange = seekableRange?.end;
          nextDuration = sectionInfo.duration;
        }
      } else if (accessControl === "NPVR") {
        // npvr case? best effort to be able to seek
        nextDuration = seekableRange.end;
        nextCurrentTime = incomingCurrentTime;
      } else {
        nextCurrentTime = -1;
      }
    }
    setDuration(nextDuration);
    setCurrentTime(nextCurrentTime);
    setSeekableEndRange(nextSeekableEndRange);
  }, [session]);

  const setSeasonEpisodeTitle = useCallback(
    (content?: Content) => {
      const episode = content?.metadata?.series?.episode;
      const season = content?.metadata?.series?.season;
      if (episode && season) {
        setSecondaryTitle(
          `${translations?.season || "Season"} ${season} • ${
            translations?.episode || "Episode"
          } ${episode}`
        );
      } else {
        setSecondaryTitle(undefined);
      }
    },
    [translations?.episode, translations?.season]
  );

  // set language tracks when rejoining a session (i.e. on page refresh while casting)
  const setActiveTracks = useCallback(() => {
    // wait for active tracks
    if (!session?.sessionState?.playbackSessionState?.tracks?.active) return;
    const {
      sessionState: {
        playbackSessionState: {
          tracks: {
            active: { audio: activeAudio, text: activeText },
            audio,
            text,
          },
        },
      },
    } = session;
    if (text) setTextTracks([OFF_TEXT_TRACK, ...text]);
    if (audio) setAudioTracks(audio);
    if (activeText) setActiveTextTrack(activeText);
    if (text && !activeText) setActiveTextTrack(OFF_TEXT_TRACK);
    if (activeAudio) setActiveAudioTrack(activeAudio);
  }, [session, OFF_TEXT_TRACK]);

  useEffect(() => {
    const settings = {
      castFramework,
      initializedTimeout,
      loadCastFramework,
      readyTimeout,
      unifiedMessageNamespace,
    };

    const chromecastSender = Sender.getInstance(settings);
    setSender(chromecastSender);
  }, [
    castFramework,
    initializedTimeout,
    loadCastFramework,
    readyTimeout,
    unifiedMessageNamespace,
  ]);

  useEffect(() => {
    if (!sender) return () => undefined;

    const onConnectionStateChangedHandler = ({
      connectionState,
    }: {
      connectionState: TConnectionStates;
    }) => {
      setSenderState(connectionState);
    };

    const onDevicesFoundHandler = ({
      devicesFound: incomingDevicesFound,
    }: {
      devicesFound: boolean;
    }) => {
      setDevicesFound(incomingDevicesFound);
    };

    const onSessionEnded = () => {
      setSession(null);
    };

    const onSessionStarted = ({
      session: newSession,
    }: {
      session: TUnfortunatelyAny;
    }) => {
      setSession(newSession);
    };

    sender
      .on(SenderEvents.ConnectionStateChanged, onConnectionStateChangedHandler)
      .on(SenderEvents.DevicesChanged, onDevicesFoundHandler)
      .on(SenderEvents.SessionEnded, onSessionEnded)
      .on(SenderEvents.SessionStarted, onSessionStarted);

    if (!sender.hasResolvedInitialized && currentAppId) {
      const options = {
        applicationId: currentAppId,
        pauseResumeOnDialogOpen: true,
        unifiedMessageNamespace,
      };
      sender.initialize(options);
    }

    return () => {
      sender
        .off(
          SenderEvents.ConnectionStateChanged,
          onConnectionStateChangedHandler
        )
        .off(SenderEvents.DevicesChanged, onDevicesFoundHandler)
        .off(SenderEvents.SessionEnded, onSessionEnded)
        .off(SenderEvents.SessionStarted, onSessionStarted);
    };
  }, [
    currentAppId,
    unifiedMessageNamespace,
    sender,
    dispatchStateChanged,
    resetState,
  ]);

  const onContentStoppedHandler = useCallback(
    ({ reason }: { reason: string }) => {
      switch (reason) {
        case StoppedReasons.INTERRUPTED:
        case StoppedReasons.NEXT_CONTENT_AUTOMATIC:
        case StoppedReasons.NEXT_CONTENT_CHOICE:
        case StoppedReasons.RELATED_CONTENT_AUTOMATIC:
        case StoppedReasons.RELATED_CONTENT_CHOICE:
          setIsActive(true);
          break;
        default:
          setIsActive(false);
          break;
      }

      dispatchStateChanged();
      contentIdRef.current = undefined;
    },
    [dispatchStateChanged]
  );

  const onPlaybackStateChangedHandler = useCallback(
    ({ playbackState }: { playbackState: string }) => {
      switch (playbackState) {
        case PlaybackStates.Buffered:
          setIsBuffering(false);
          break;
        case PlaybackStates.Buffering:
          setIsBuffering(true);
          break;
        case PlaybackStates.Paused:
          setIsPaused(true);
          break;
        case PlaybackStates.Playing:
          setIsPaused(false);
          break;
        case PlaybackStates.Seeked:
          setIsSeeking(false);
          break;
        case PlaybackStates.Seeking:
          setIsSeeking(true);
          break;
        case PlaybackStates.Stopped:
          setIsActive(false);
          break;
        case PlaybackStates.Unknown:
          // not sure how/if we need to handle this
          break;
        default:
          break;
      }
    },
    [setIsPaused, setIsBuffering, setIsSeeking, setIsActive]
  );

  const onTextTrackChangedHandler = useCallback(
    (track: Track) => {
      if (
        !session ||
        !track ||
        !session?.sessionState?.playbackSessionState?.tracks?.text
      )
        return;

      const {
        sessionState: {
          playbackSessionState: {
            tracks: {
              active: { text: activeText },
              text,
            },
          },
        },
      } = session;

      if (text) {
        setTextTracks([OFF_TEXT_TRACK, ...text]);
      }
      setActiveTextTrack(activeText || OFF_TEXT_TRACK);
    },
    [OFF_TEXT_TRACK, session]
  );

  const onAudioTrackChangedHandler = useCallback(
    (track: Track) => {
      if (
        !session ||
        !track ||
        !session?.sessionState?.playbackSessionState?.tracks?.audio
      )
        return;

      const {
        sessionState: {
          playbackSessionState: {
            tracks: {
              active: { audio: activeAudio },
              audio,
            },
          },
        },
      } = session;

      if (audio) {
        setAudioTracks(audio);
      }
      setActiveAudioTrack(activeAudio);
    },
    [session]
  );

  const onTimeUpdateHandler = useCallback(() => {
    updateTimeStates();
  }, [updateTimeStates]);

  const onStreamRestrictionsUpdatedHandler = useCallback(
    ({ streamRestrictions }: { streamRestrictions: TUnfortunatelyAny }) => {
      setCanPause(!streamRestrictions.pause);
      setCanSeek(!streamRestrictions.seek);
    },
    []
  );

  // We get metadata for whatever content we're playing
  const onContentUpdatedHandler = useCallback(
    ({ content }: { content: TUnfortunatelyAny }) => {
      if (!content || !content.metadata) return;
      setTitle(
        content?.metadata?.series?.title || content?.metadata?.title || ""
      );
      setSeasonEpisodeTitle(content);
      updateTimeStates();
      setActiveTracks();
      setIsActive(true);
    },
    [setSeasonEpisodeTitle, setTitle, updateTimeStates, setActiveTracks]
  );

  // We're playing new content, as in next episode of something
  const onContentChangedHandler = useCallback(
    ({ content }: { content: Content }) => {
      if (!content) return;
      contentIdRef.current = content.contentId;
      if (!content.metadata) return;
      setTitle(
        content?.metadata?.series?.title || content?.metadata?.title || ""
      );
      setSeasonEpisodeTitle(content);
      updateTimeStates();
    },
    [setSeasonEpisodeTitle, updateTimeStates]
  );

  // Session event listeners
  useEffect(() => {
    if (!session) return () => undefined;

    session
      .on(SessionEvents.ContentChanged, onContentChangedHandler)
      .on(SessionEvents.ContentUpdated, onContentUpdatedHandler)
      .on(SessionEvents.ContentStopped, onContentStoppedHandler)
      .on(
        SessionEvents.StreamRestrictionsUpdated,
        onStreamRestrictionsUpdatedHandler
      )
      .on(SessionEvents.TimeUpdate, onTimeUpdateHandler)
      .on(SessionEvents.AudioTrackChanged, onAudioTrackChangedHandler)
      .on(SessionEvents.TextTrackChanged, onTextTrackChangedHandler)
      .on(SessionEvents.PlaybackStateChanged, onPlaybackStateChangedHandler);

    return () => {
      session
        .off(SessionEvents.ContentChanged, onContentChangedHandler)
        .off(SessionEvents.ContentUpdated, onContentUpdatedHandler)
        .off(
          SessionEvents.StreamRestrictionsUpdated,
          onStreamRestrictionsUpdatedHandler
        )
        .off(SessionEvents.TimeUpdate, onTimeUpdateHandler)
        .off(SessionEvents.AudioTrackChanged, onAudioTrackChangedHandler)
        .off(SessionEvents.TextTrackChanged, onTextTrackChangedHandler)
        .off(SessionEvents.PlaybackStateChanged, onPlaybackStateChangedHandler);
    };
  }, [
    session,
    onContentChangedHandler,
    onPlaybackStateChangedHandler,
    onContentUpdatedHandler,
    onAudioTrackChangedHandler,
    onTextTrackChangedHandler,
    onStreamRestrictionsUpdatedHandler,
    onTimeUpdateHandler,
    onContentStoppedHandler,
  ]);

  useEffect(() => {
    if ((!newApplicationId && !currentAppId) || !sender) return;
    if (!currentAppId) {
      setCurrentAppId(newApplicationId);
    }
    if (currentAppId !== newApplicationId) {
      const settings = {
        castFramework,
        initializedTimeout,
        loadCastFramework,
        readyTimeout,
        unifiedMessageNamespace,
      };
      // TODO: Check for a better way to switch receiver apps when appid changes (for refapp)
      const newSender = Sender.getInstance(settings);
      setSender(newSender);
      setCurrentAppId(newApplicationId);
    }
  }, [
    newApplicationId,
    setCurrentAppId,
    currentAppId,
    sender,
    castFramework,
    initializedTimeout,
    loadCastFramework,
    readyTimeout,
    unifiedMessageNamespace,
    resetState,
  ]);

  const togglePlay = useCallback(() => {
    if (!session) return;
    if (isPaused) {
      session.play();
    } else {
      session.pause();
    }
  }, [session, isPaused]);

  const stop = useCallback(() => {
    if (!session) return;
    session.stop();
  }, [session]);

  const mute = useCallback(() => {
    if (!session) return;
    setIsMuted(true);
    session.setMuted(true);
  }, [session]);

  const unmute = useCallback(() => {
    if (!session) return;
    setIsMuted(false);
    session.setMuted(false);
  }, [session]);

  const seek = useCallback(
    (time: number) => {
      if (!session) return;
      session.seek(time);
    },
    [session]
  );

  const setVolume = useCallback(
    (volume: number) => {
      if (!session) return;

      setActiveVolume(volume);
      session.setVolume(volume / 100);
    },
    [session]
  );

  const setTextTrack = useCallback(
    (track: Track) => {
      if (!session) return;

      setActiveTextTrack(track);
      session.setTextTrack(track);
    },
    [session]
  );

  const setAudioTrack = useCallback(
    (track: Track) => {
      if (!session) return;

      setActiveAudioTrack(track);
      session.setAudioTrack(track);
    },
    [session]
  );

  const isChromecastBarVisible =
    senderState === ConnectionStates.Connected && isActive;

  const ccContextValue = useMemo(
    () => ({
      credentials,
      devicesFound,
      isChromecastBarVisible,
      preferredLanguage,
      sender,
      senderState,
      serviceCountry,
      translations,
    }),
    [
      credentials,
      preferredLanguage,
      sender,
      senderState,
      devicesFound,
      serviceCountry,
      translations,
      isChromecastBarVisible,
    ]
  );

  return (
    <ThemeProvider breakpoints={breakpoints} designTokens={designTokens}>
      <ChromecastContext.Provider value={ccContextValue}>
        {children}

        {isChromecastBarVisible && (
          <ChromecastBar
            activeAudioTrack={activeAudioTrack}
            activeTextTrack={activeTextTrack}
            activeVolume={activeVolume}
            audioTracks={audioTracks}
            canPause={canPause}
            canSeek={canSeek}
            currentTime={currentTime}
            duration={duration}
            isLoading={isLoading || isBuffering || isSeeking}
            isMuted={isMuted}
            isPaused={isPaused}
            onMute={mute}
            onSeek={seek}
            onSetAudioTrack={setAudioTrack}
            onSetTextTrack={setTextTrack}
            onSetVolume={setVolume}
            onStop={stop}
            onTogglePlay={togglePlay}
            onUnmute={unmute}
            secondaryTitle={secondaryTitle}
            seekableEndRange={seekableEndRange}
            showProgressBar={currentTime !== -1}
            textTracks={textTracks}
            title={title}
          />
        )}
      </ChromecastContext.Provider>
    </ThemeProvider>
  );
};
