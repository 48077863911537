import { ConnectionStates } from "@telia-company/tv.unified-sender-js";

import { useChromecastContext } from "~/providers";

export const useIsCasting = () => {
  const { sender, senderState } = useChromecastContext();
  return (
    sender?.connected ||
    senderState === ConnectionStates.Connected ||
    senderState === ConnectionStates.Connecting
  );
};
