import {
  calculateLanguagePreferences,
  TUnfortunatelyAny,
  TUserConfig,
} from "@telia-company/tv.web-player-shared";

export type TSetPreferredLanguages = {
  player: TUnfortunatelyAny | undefined;
  user?: TUserConfig;
};

export const setPreferredLanguages = ({
  player,
  user,
}: TSetPreferredLanguages): void => {
  const { audioLang, fallbackAudioLang, subVisibility, textLang } =
    calculateLanguagePreferences(user);

  player.configure({
    preferredAudioLanguage: audioLang,
    preferredTextLanguage: !subVisibility ? "" : textLang,
  });

  player.setTextTrackVisibility(subVisibility);

  // listen for shaka streaming event, when tracks are known, and update to fallback language if needed
  const setLanguage = () => {
    const availableLanguages = player.getAudioLanguages();

    // check if we should use a fallback language
    if (availableLanguages.indexOf(audioLang) === -1) {
      // the preferred language is not available, use fallback
      player.selectAudioLanguage(fallbackAudioLang);
    }

    player.removeEventListener("streaming", setLanguage);
  };
  player.addEventListener("streaming", setLanguage);
};
