import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Track } from "@telia-company/tv.unified-sender-js";
import React from "react";

import { IconButton } from "~/components/IconButton";
import { SvgPause, SvgPlay, SvgStop } from "~/components/Icons";
import { LanguagePicker } from "~/components/LanguagePicker";
import { Spinner } from "~/components/Spinner";

import { AssetInfo } from "./AssetInfo";
import { ProgressBar } from "./ProgressBar";
import { useMiniControlsSpacer } from "./useMiniControlsSpacer";
import { VolumeControls } from "./VolumeControls";

const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  top: 2px; // half of the bar height
  transform: translateY(-50%);
`;

const StyledChromecastBar = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.designTokens.color.background.tertiary};
  `}
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0px;
  position: fixed;
  height: 108px;
  width: 100%;
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.S}px) {
      height: 124px;
    }
  `}
  z-index: 1;
`;

const StyledPlayerControllers = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`;

const StyledLeftControls = styled.div`
  flex-direction: row;
  display: flex;
`;

const StyledRightControls = styled.div`
  display: flex;
  flex: none;
  flex-direction: row;
  margin-left: auto;
`;

export type ChromecastBarProps = {
  activeAudioTrack?: Track;
  activeTextTrack?: Track;
  activeVolume: number;
  audioTracks?: Track[];
  canPause: boolean;
  canSeek: boolean;
  className?: string;
  currentTime: number;
  duration: number;
  isLoading: boolean;
  isMuted: boolean;
  isPaused: boolean;
  onMute: () => void;
  onSeek: (time: number) => void;
  onSetAudioTrack: (track: Track) => void;
  onSetTextTrack: (track: Track) => void;
  onSetVolume: (volume: number) => void;
  onStop: () => void;
  onTogglePlay: () => void;
  onUnmute: () => void;
  secondaryTitle?: string;
  seekableEndRange?: number;
  showProgressBar: boolean;
  textTracks?: Track[];
  title?: string;
};

export const ChromecastBar: React.FC<ChromecastBarProps> = ({
  activeAudioTrack,
  activeTextTrack,
  activeVolume,
  audioTracks,
  canPause,
  canSeek,
  className,
  currentTime,
  duration,
  isLoading,
  isMuted,
  isPaused,
  onMute,
  onSeek,
  onSetAudioTrack,
  onSetTextTrack,
  onSetVolume,
  onStop,
  onTogglePlay,
  onUnmute,
  secondaryTitle,
  seekableEndRange,
  showProgressBar,
  textTracks,
  title,
}) => {
  useMiniControlsSpacer();

  return (
    <StyledChromecastBar className={className}>
      {showProgressBar && (
        <StyledProgressBar
          canSeek={canSeek}
          currentTime={currentTime}
          disabled={isLoading}
          duration={duration}
          onSeeking={onSeek}
          seekableEndRange={seekableEndRange}
        />
      )}
      <StyledPlayerControllers>
        <StyledLeftControls>
          {isLoading ? (
            <Spinner />
          ) : canPause ? (
            <IconButton disabled={isLoading} onClick={onTogglePlay}>
              {isPaused ? <SvgPlay /> : <SvgPause />}
            </IconButton>
          ) : (
            <IconButton disabled={isLoading} onClick={onStop}>
              <SvgStop />
            </IconButton>
          )}
        </StyledLeftControls>
        <AssetInfo secondaryTitle={secondaryTitle} title={title} />
        <StyledRightControls>
          {((audioTracks && audioTracks?.length > 1) ||
            (textTracks && textTracks?.length > 1)) && (
            <LanguagePicker
              activeAudioTrack={activeAudioTrack}
              activeTextTrack={activeTextTrack}
              audioTracks={audioTracks}
              disabled={isLoading}
              onChangeAudioTrack={onSetAudioTrack}
              onChangeTextTrack={onSetTextTrack}
              textTracks={textTracks}
            />
          )}
          <VolumeControls
            changeVolume={(soundState) => onSetVolume(soundState.vol * 100)}
            disabled={isLoading}
            isMuted={isMuted}
            toggleMute={() => (isMuted ? onUnmute() : onMute())}
            volume={activeVolume / 100}
          />
        </StyledRightControls>
      </StyledPlayerControllers>
    </StyledChromecastBar>
  );
};
