import { PlaybackEventTypes } from "@telia-company/tv.web-playback-sdk";
import {
  PlayerControls,
  TCallback,
  TEventMethods,
  TWildcardEventHandler,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";

export type TAttachMediaSessionOptions = {
  controls: PlayerControls;
  endSession: TCallback;
  event: TEventMethods;
};

export type THandlerPair = [MediaSessionAction, MediaSessionActionHandler];

const supportsMediaSession = "mediaSession" in navigator;

const supportsPositionState =
  supportsMediaSession && "setPositionState" in navigator.mediaSession;

export const attachMediaSession = ({
  controls,
  endSession,
  event: { off, on },
}: TAttachMediaSessionOptions) => {
  if (!supportsMediaSession) return;

  let duration: null | number = null;
  let preventSeek = true;

  const mediaSessionHandlerPairs: THandlerPair[] = [
    ["play", controls.play],
    ["pause", controls.pause],
    ["stop", endSession],
    [
      "seekbackward",
      ({ seekOffset }) => {
        const currentPosition = controls.getPosition();
        if (typeof currentPosition === "number") {
          controls.seek(currentPosition - (seekOffset || 10));
        }
      },
    ],
    [
      "seekforward",
      ({ seekOffset }) => {
        const currentPosition = controls.getPosition();
        if (typeof currentPosition === "number") {
          controls.seek(currentPosition + (seekOffset || 10));
        }
      },
    ],
    [
      "seekto",
      ({ seekTime }: MediaSessionActionDetails) => {
        if (seekTime) {
          controls.seek(seekTime);
        }
      },
    ],
  ];

  const eventHandler: TWildcardEventHandler = (_, event) => {
    switch (event.type) {
      case WebPlayerEventType.PLAYBACK_RESTRICTIONS:
        if (typeof event.payload.trickPlayRestrictions?.noRewind !== "boolean")
          return;

        preventSeek = event.payload.trickPlayRestrictions.noRewind;
        break;
      case PlaybackEventTypes.PLAYING:
        navigator.mediaSession.playbackState = "playing";
        break;
      case PlaybackEventTypes.PAUSED:
        navigator.mediaSession.playbackState = "paused";
        break;
      case PlaybackEventTypes.TIME_UPDATE:
        if (!supportsPositionState) return;
        if (preventSeek) return;
        if (!duration) return;
        if (duration === Infinity) return;
        // no-seek live case
        if (event.payload.currentTime > duration) return;

        navigator.mediaSession.setPositionState({
          duration,
          playbackRate: 1,
          position:
            typeof event.payload.currentTime === "number"
              ? event.payload.currentTime
              : undefined,
        });
        break;
      case WebPlayerEventType.SEEK_RANGE_UPDATE:
        if (!event.payload.seekRange.end) return;

        duration = event.payload.seekRange.end - event.payload.seekRange.start;
        break;
      case PlaybackEventTypes.STOPPED:
        navigator.mediaSession.metadata = null;
        navigator.mediaSession.playbackState = "none";

        if (supportsPositionState) {
          navigator.mediaSession.setPositionState(undefined);
        }

        mediaSessionHandlerPairs.forEach(([evt]) =>
          navigator.mediaSession.setActionHandler(evt, null)
        );

        // Unsubscribe from global event handler
        off("*", eventHandler);
        break;
      default:
        break;
    }
  };

  // Subscribe to global even handler
  on("*", eventHandler);

  mediaSessionHandlerPairs.forEach(([evt, handler]) =>
    navigator.mediaSession.setActionHandler(evt, handler)
  );
};
