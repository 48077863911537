import {
  DrmType,
  ErrorCategories,
  StandardError,
} from "@telia-company/tv.web-playback-sdk";
import {
  convertShakaError,
  isErrorFatalFn,
  overrideCodeFn,
  TShakaError,
} from "@telia-company/tv.web-player-shaka-util";
import {
  AccessControl,
  isSafari15OrGreater,
  VideoIdType,
  WatchMode,
} from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

import { TEngineMethodOptions } from "../../@types/types";
import { canBrowserPlayVideo } from "./capabilities";
import { setupStaticDynamicManifestSwitchHandler } from "./detect-manifest-change";
import { setUpDRM } from "./drm-config";
import { setPreferredLanguages } from "./preferred-languages";
import { setupRequestResponseFilter } from "./request-response-filter";
import { safari15StartoverFix, safariStartoverFix } from "./workarounds";

export const getLoadFn = (options: TEngineMethodOptions) => (): void => {
  if (!canBrowserPlayVideo()) {
    const fault = new StandardError({
      category: ErrorCategories.DEFAULT,
      code: "UNSUPPORTED_DEVICE",
      details: {
        description: "[Shaka] Browser is not supported.",
        domain: "capabilities",
        origin: "canBrowserPlayVideo",
      },
      fatal: true,
    });

    options.callbacks.onError({
      error: fault,
    });

    return;
  }

  const { playback, restrictions, selectedStream, user } = options;

  const streamUrl = selectedStream.url as string;
  const isHls = streamUrl.toLowerCase().indexOf("m3u") > -1;
  // For Safari we need to specify the MIME type in the load request, otherwise HLS_CMAF from yospace won't play, probably due to the semicolon in the url
  const isHlsCMAF = selectedStream.packaging === "HLS_CMAF_CBCS";
  const isDash = streamUrl.toLowerCase().indexOf(".mpd") > -1;
  const isSafari = selectedStream.drm?.type === DrmType.FAIRPLAY;
  const noDrm = selectedStream.drm?.type === DrmType.NONE;
  const isVod = playback.playbackSpec.watchMode === WatchMode.ONDEMAND;
  const isStartover = playback.playbackSpec.watchMode === WatchMode.STARTOVER;
  const isNpvrLiveRecording =
    playback.playbackSpec.watchMode === WatchMode.LIVE &&
    playback.playbackSpec.videoIdType === VideoIdType.MEDIA &&
    playback.playbackSpec.accessControl === AccessControl.NPVR;

  if (restrictions) {
    options.player.configure({
      restrictions,
    });
  }

  setUpDRM({
    drm: selectedStream.drm,
    player: options.player,
  });

  // some content can be started as vod (ONDEMAND) but is actually live,
  // this means we need to apply the startover manifest filter to detect
  // when it switches from live to vod (dynamic -> static)
  if (isStartover || isNpvrLiveRecording || isVod) {
    if (isDash) {
      setupStaticDynamicManifestSwitchHandler(options);
    }
  }

  if (isStartover) {
    if (isHls) {
      if (isSafari15OrGreater) {
        safari15StartoverFix({
          videoElement: options.domElements.videoElement,
        });
      } else {
        safariStartoverFix({
          videoElement: options.domElements.videoElement,
        });
      }
    }
  }

  setupRequestResponseFilter({ isDash, isHls, isVod, noDrm, options });

  options.shakaEventHandler.addEventListeners();
  options.videoEventHandler.addEventListeners();
  if (isHls) {
    options.hlsStallEventHandler.addEventListeners();
  }

  if (isSafari) {
    options.nativeCueMetadataHandler.addEventListeners();
  }

  setPreferredLanguages({ player: options.player, user });

  if (process.env.REACT_APP_DEBUG_PLAYER) {
    // eslint-disable-next-line no-console
    console.log(
      "%cPLAYER DEBUG ENABLED: window.debug %s",
      "color:#1975FF;font-weight:bold;"
    );
    // eslint-disable-next-line no-console
    console.log(
      "%cShaka Version: %s",
      "color:#FF6619FF;font-weight:bold;",
      shaka.Player.version
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.debug = {
      ...options,
      shakaVersion: shaka.Player.version,
    };
  }

  options.player
    .load(
      streamUrl,
      playback.startTimeInSeconds,
      isHlsCMAF ? "application/vnd.apple.mpegurl" : undefined
    )
    .catch((error: TShakaError) => {
      const fault = convertShakaError({
        engineInitTimestamp: options.engineInitTimestamp,
        error,
        extraDebugData: {
          drmKeyStatus: options.player.getKeyStatuses(),
        },
        isErrorFatalFn,
        overrideCodeFn,
        stats: options.player.getStats(),
        videoElement: options.domElements.videoElement,
      });

      if (fault.code === "SHAKA:PLAYER:LOAD_INTERRUPTED") {
        // eslint-disable-next-line no-console
        console.warn("Shaka load interrupted.");
        return;
      }

      if (fault.fatal) {
        options.callbacks.onError({
          error: fault,
        });
      } else {
        options.callbacks.onCustomTrackingEvent({
          name: `WARNING:${fault.code}`,
        });
      }
    });
};
