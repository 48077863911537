import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import { TPlaybackSpec, TPlayerConfig } from "@telia-company/tv.web-player-ui";
import { Dispatch, SetStateAction, useEffect } from "react";

import { cw, ml } from "../misc/queries";
import { imageProxy } from "../misc/util";
import { useRefAppState } from "../reducer/ref.reducer";

export type Props = {
  setCwItems: Dispatch<SetStateAction<TUnfortunatelyAny>>;
  setMlItems: Dispatch<SetStateAction<TUnfortunatelyAny>>;
};

export type PanelItem = {
  img: string;
  playbackSpec: TPlaybackSpec;
  title: string;
};

const posterRatio = 3 / 2;
const posterWidth = 200;

export type Panel = PanelItem[];

const convertToPanel = (items: TUnfortunatelyAny): Panel =>
  items
    .map(
      (i: TUnfortunatelyAny): PanelItem => ({
        img:
          (i?.media?.images?.showcard2x3?.source &&
            imageProxy({
              height: Math.round(posterWidth * posterRatio),
              uri: i?.media?.images?.showcard2x3?.source,
              width: posterWidth,
            })) ||
          "",
        playbackSpec:
          i.media.playback?.play?.subscription?.[0]?.item?.playbackSpec ||
          i.media.playback?.play?.rental?.[0]?.item?.playbackSpec ||
          i.media.playback?.play?.linear?.[0]?.item?.playbackSpec,
        title: i.media?.title,
      })
    )
    .filter((i: PanelItem) => i.playbackSpec);

const getRequestOptions = (playerConf: TPlayerConfig, query: string) => ({
  body: JSON.stringify({
    operationName: "cw",
    query,
    variables: {
      limit: 5,
    },
  }),
  headers: {
    "client-name": "web",
    "client-version": "0.0.0",
    "tv-client-boot-id": playerConf.applicationSessionId,
    "X-Country": playerConf.serviceCountry.toUpperCase(),
  },
  ignoreGlobalHeaders: false,
  useAuthentication: true,
});

export const useContentPanels = ({ setCwItems, setMlItems }: Props): void => {
  const { playerConf, requestFactory, userId } = useRefAppState();

  useEffect(() => {
    if (!playerConf) return;
    if (!userId) return;

    Promise.all([
      requestFactory.fetch(
        `${playerConf.graphUrl}`,
        getRequestOptions(playerConf, cw)
      ),
      requestFactory.fetch(
        `${playerConf.graphUrl}`,
        getRequestOptions(playerConf, ml)
      ),
    ])
      .then(([cwResult, mlResult]) => {
        const cwList = cwResult.responseBody?.data?.continueWatchingList?.items;
        const myList = mlResult.responseBody?.data?.myList?.items;

        if (cwList) {
          if (cwResult.responseBody.errors) {
            // eslint-disable-next-line no-console
            console.error("Graph Error:", cwResult.responseBody.errors);
          }

          setCwItems(convertToPanel(cwList.slice(0, 5)));
        }

        if (myList) {
          const filtered = myList
            .filter((i: TUnfortunatelyAny) => i.media)
            .slice(0, 5);

          if (mlResult.responseBody.errors) {
            // eslint-disable-next-line no-console
            console.error("Graph Error:", cwResult.responseBody.errors);
          }

          setMlItems(convertToPanel(filtered));
        }
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [playerConf, requestFactory, setCwItems, setMlItems, userId]);
};
