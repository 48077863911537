import cmoreDesignToken from "@telia-company/tv.one-design-system-web/legacy/desktop/cmore/tokens.json";
import teliaDesignToken from "@telia-company/tv.one-design-system-web/legacy/desktop/telia/tokens.json";
import { ChromecastProvider } from "@telia-company/tv.web-player-cc-sender";
import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";

export type ChromecastThingProps = {
  auth: {
    profileHeader: null | string;
    refreshToken: string | undefined;
  } | null;
  children?: ReactNode;
  env: string;
  serviceCountry: string;
};

export const ChromecastThing: FC<ChromecastThingProps> = ({
  auth,
  children,
  env,
  serviceCountry,
}) => {
  const [designToken, setDesignToken] = useState<TUnfortunatelyAny>();
  const [applicationId, setApplicationId] = useState<string | undefined>("");

  const getApplicationIds = useCallback(() => {
    const applicationIds = [
      {
        id: "0D86F1F9",
        name: "CMORE_STAGE",
      },
      {
        id: "1095029F",
        name: "CMORE_PROD",
      },
      {
        id: "0142F0D4",
        name: "TELIA_STAGE",
      },
      {
        id: "14839D9B",
        name: "TELIA_PROD",
      },
    ];
    const appid = applicationIds.find((i) => i.name === env)?.id;
    return appid;
  }, [env]);

  useEffect(() => {
    setApplicationId(getApplicationIds());
    setDesignToken(
      env[0].toUpperCase() === "C" ? cmoreDesignToken : teliaDesignToken
    );
  }, [env, serviceCountry, getApplicationIds]);

  if (!applicationId || getApplicationIds() !== applicationId) {
    return null;
  }

  return designToken ? (
    <ChromecastProvider
      applicationId={applicationId}
      breakpoints={{
        L: 1025,
        M: 769,
        S: 480,
        XL: 1440,
      }}
      credentials={{
        autoLogin: true,
        password: "",
        profileHeader: auth?.profileHeader || "",
        token: auth?.refreshToken || "",
        type: "TOKEN",
        username: "",
      }}
      designTokens={designToken}
      onStateChanged={() => {}}
      preferredLanguage={{
        audio: ["swe"],
        text: ["swe"],
      }}
      serviceCountry={serviceCountry}
      translations={{
        audioTracks: "Ljudspår",
        episode: "Episod",
        off: "Av",
        season: "Säsong",
        subtitleTracks: "Undertexter",
      }}
    >
      {children}
    </ChromecastProvider>
  ) : null;
};
