export const cw = `
query cw ($limit: Int!) {
  continueWatchingList (limit: $limit) {
    items {
      media {
        ... on Episode {
          title
          playback {
            play {
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
        
        ... on Movie {
          title
          playback {
            play {
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
        
        ... on SportEvent {
          title
          playback {
            play {
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
      }
    }
  }
}
`;

export const ml = `
query cw ($limit: Int!) {
  myList (limit: $limit) {
    items {
      media {        
        ... on Movie {
          title
          playback {
            play {
              npvr {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
        
        ... on SportEvent {
          title
          playback {
            play {
              npvr {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
      }
    }
  }
}
`;
